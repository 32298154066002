.container {
  width: 100%;
  overflow: auto;
  padding: 1rem 0.2rem;
  height: 100%;
  margin: 1.25rem 0;

  & > * {
    display: flex;
    flex-wrap: wrap;
  }

  .chipRow {
    gap: 0.2rem;
    margin-bottom: 0.2rem;

    > .rowIcon {
      border-radius: 0.3rem;
      box-sizing: border-box;
      padding: 0.2rem;
    }

    > .item {
      cursor: pointer;
      border-radius: 0.3rem;
      box-sizing: border-box;
      padding: 0.2rem;
      overflow: hidden;
      text-overflow: clip;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.5rem;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
