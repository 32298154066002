/* stylelint-disable meowtec/no-px */
table.matchingTable * {
  box-sizing: border-box;
}

table.matchingTable,
.divTable {
  width: fit-content;
  border: 0;
  border-spacing: 0;
  position: relative;
}

.tr {
  display: flex;
}

table.matchingTable tr,
table.matchingTable .tr {
  width: fit-content;
  height: 30px;
}

table.matchingTable tbody tr:nth-child(odd),
table.matchingTable tbody .tr:nth-child(odd) {
  background-color: rgb(0 0 0 / 10%);
}

table.matchingTable tbody tr:hover,
table.matchingTable tbody .tr:hover {
  background-color: rgb(0 0 0 / 20%);
}

table.matchingTable th,
table.matchingTable .th,
table.matchingTable td,
table.matchingTable .td {
  padding: 0.25rem;
  position: relative;
}

table.matchingTable th,
table.matchingTable .th {
  padding: 2px 4px;
  font-weight: bold;
  text-align: center;
  height: 30px;
  border: 1px solid lightgray;
}

table.matchingTable td,
table.matchingTable .td {
  min-height: 30px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding-top: 30px;
}

table.matchingTable .resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgb(0 0 0 / 50%);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

table.matchingTable .resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  table.matchingTable .resizer {
    opacity: 0;
  }

  table.matchingTable *:hover > .resizer {
    opacity: 1;
  }
}
